import React , { Component }from 'react'

class bloggrid extends Component {
    componentDidMount(){
       

    }
  render() {
  return (
    <>
  <section className="inner-banner">
        <div className="banner-curve"></div>
		<div className="auto-container">
            <div className="inner">
                <div className="theme-icon"></div>
    			<div className="title-box">
                    <h1>DevOps Industry News</h1>
                    <div className="d-text">Building a relationship between IT Services</div>
                </div>
            </div>
		</div>
    </section>

    <section className="news-section blog-grid">
        <div className="auto-container">
            <div className="row clearfix">
               
                <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                        <div className="image-box">
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7228050889540780032" target='_blank'><img src={require('../../images/resource/blog-image-1.jpg')}alt="blog-image-1" title=""/></a>
                        </div>
                        <div className="lower-box">
                            <div className="category">websitedevelopment</div>
                            <h3><a href='https://www.linkedin.com/feed/update/urn:li:activity:7228050889540780032' target='_blank'>8 factors affecting your page speed need to be identified and rectified for a better UX.</a></h3>
                            <div className="meta-info">
                                <ul className="clearfix">
                                    <li><a href="#">By Admin</a></li>
                                    <li><a href="#">Aug 2024</a></li>
                                </ul>
                            </div>
                            <div className="more-link"><a href='https://www.linkedin.com/feed/update/urn:li:activity:7228050889540780032' target='_blank'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>
                </div>
               
                <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                        <div className="image-box">
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7223707163104358400" target='_blank'><img src={require('../../images/resource/blog-image-2.jpg')} alt="blog-image-2" title=""/></a>
                        </div>
                        <div className="lower-box">
                            <div className="category">DevOps</div>
                            <h3><a href='https://www.linkedin.com/feed/update/urn:li:activity:7223707163104358400' target='_blank'>Dive into our latest guide and discover how to reverse engineer your infrastructure for consistency, scalability, and efficiency.</a></h3>
                            <div className="meta-info">
                                <ul className="clearfix">
                                    <li><a href="#">By Admin</a></li>
                                    <li><a href="#">July 2024</a></li>
                                </ul>
                            </div>
                            <div className="more-link"><a href='https://www.linkedin.com/feed/update/urn:li:activity:7223707163104358400' target='_blank'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>
                </div>
               
                <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                        <div className="image-box">
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7221907555785048065" target='_blank'><img src={require('../../images/resource/blog-image-3.jpg')} alt="blog-image-3" title=""/></a>
                        </div>
                        <div className="lower-box">
                            <div className="category">Devops Tool</div>
                            <h3><a href='https://www.linkedin.com/feed/update/urn:li:activity:7221907555785048065' target='_blank'>A practical Guide for continuous Integration with Jenkins</a></h3>
                            <div className="meta-info">
                                <ul className="clearfix">
                                    <li><a href="#">By Admin</a></li>
                                    <li><a href="#">July 2024</a></li>
                                </ul>
                            </div>
                            <div className="more-link"><a href='https://www.linkedin.com/feed/update/urn:li:activity:7221907555785048065' target='_blank'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>
                </div>
               
                <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                        <div className="image-box">
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7220449655019515904" target='_blank'><img src={require('../../images/resource/blog-image-4.jpg')} alt="blog-image-4" title=""/></a>
                        </div>
                        <div className="lower-box">
                            <div className="category">Website News</div>
                            <h3><a href='https://www.linkedin.com/feed/update/urn:li:activity:7220449655019515904t' target='_blank'>Why You Should Not Settle for a Basic Website for Your Business.</a></h3>
                            <div className="meta-info">
                                <ul className="clearfix">
                                    <li><a href="#">By Admin</a></li>
                                    <li><a href="#">June 2024</a></li>
                                </ul>
                            </div>
                            <div className="more-link"><a href='https://www.linkedin.com/feed/update/urn:li:activity:7220449655019515904' target='_blank'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>
                </div>
               
                <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                        <div className="image-box">
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7219756202136674304" target='_blank'><img src={require('../../images/resource/blog-image-5.jpg')} alt="blog-image-5" title=""/></a>
                        </div>
                        <div className="lower-box">
                            <div className="category">Terraform</div>
                            <h3><a href='https://www.linkedin.com/feed/update/urn:li:activity:7219756202136674304' target='_blank'>Step by step guide to start your first terraform configurations with</a></h3>
                            <div className="meta-info">
                                <ul className="clearfix">
                                    <li><a href="#">By Admin</a></li>
                                    <li><a href="#">June 2024</a></li>
                                </ul>
                            </div>
                            <div className="more-link"><a href='https://www.linkedin.com/feed/update/urn:li:activity:7219756202136674304' target='_blank'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>
                </div>
               
                <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                        <div className="image-box">
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7199003288430600192/" target='_blank'><img src={require('../../images/resource/blog-image-6.jpg')} alt="blog-image-6" title=""/></a>
                        </div>
                        <div className="lower-box">
                            <div className="category">Terraform vs. Ansible</div>
                            <h3><a href='https://www.linkedin.com/feed/update/urn:li:activity:7199003288430600192/' target='_blank'>A Comparative Overview for DevOps Engineers</a></h3>
                            <div className="meta-info">
                                <ul className="clearfix">
                                    <li><a href="#">By Admin</a></li>
                                    <li><a href="#">May 2024</a></li>
                                </ul>
                            </div>
                            <div className="more-link"><a href='https://www.linkedin.com/feed/update/urn:li:activity:7199003288430600192/' target='_blank'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>
                </div>
               
                <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                        <div className="image-box">
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7201969581664395264" target='_blank'><img src={require('../../images/resource/blog-image-7.jpg')} alt="blog-image-7" title=""/></a>
                        </div>
                        <div className="lower-box">
                            <div className="category">AI tools</div>
                            <h3><a href='https://www.linkedin.com/feed/update/urn:li:activity:7201969581664395264' target='_blank'>AI tools for DevOps for enhancing efficiency and automations.</a></h3>
                            <div className="meta-info">
                                <ul className="clearfix">
                                    <li><a href="#">By Admin</a></li>
                                    <li><a href="#">May 2024</a></li>
                                </ul>
                            </div>
                            <div className="more-link"><a href='https://www.linkedin.com/feed/update/urn:li:activity:7201969581664395264' target='_blank'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>
                </div>
               
                <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                        <div className="image-box">
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7197636660266741760" target='_blank'><img src={require('../../images/resource/blog-image-8.jpg')} alt="blog-image-8" title=""/></a>
                        </div>
                        <div className="lower-box">
                            <div className="category">Jenkin</div>
                            <h3><a href='https://www.linkedin.com/feed/update/urn:li:activity:7197636660266741760' target='_blank'>Essential features and benefits of Jenkin. Real world success stories.</a></h3>
                            <div className="meta-info">
                                <ul className="clearfix">
                                    <li><a href="#">By Admin</a></li>
                                    <li><a href="#">April 2024</a></li>
                                </ul>
                            </div>
                            <div className="more-link"><a href='https://www.linkedin.com/feed/update/urn:li:activity:7197636660266741760' target='_blank'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>
                </div>
               
                <div className="news-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                    <div className="inner-box">
                        <div className="image-box">
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7196921821609271297" target='_blank'><img src={require('../../images/resource/blog-image-9.jpg')} alt="blog-image-9" title=""/></a>
                        </div>
                        <div className="lower-box">

                            <div className="category">Docker</div>
                            <h3><a href='https://www.linkedin.com/feed/update/urn:li:activity:7196921821609271297' target='_blank'>Introduction to Docker: Simplifying Software Development and Deployment.</a></h3>

                            <div className="meta-info">
                                <ul className="clearfix">
                                    <li><a href="#">By Admin</a></li>
                                    <li><a href="#">April 2024</a></li>
                                </ul>
                            </div>
                            <div className="more-link"><a href='https://www.linkedin.com/feed/update/urn:li:activity:7196921821609271297' target='_blank'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>
                </div>
            </div>
{/* 
            <div className="load-more link-box">
                <a href="https://codeops.in/blog/" target='_blank' className="theme-btn btn-style-two"><div className="btn-title">Load More News</div></a>
            </div> */}

        </div>
    </section>

    <div className="theme-separator"></div>
    </>
    )
}
}
export default bloggrid