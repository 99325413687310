import React, { Component } from 'react'
import Back5 from '../../images/background/datainfra.png';
import OwlCarousel from 'react-owl-carousel';
import {Link} from 'react-router-dom'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class cloudintegration extends Component {
  componentDidMount() {
     }
  render() {
      return (
        <>
        <section className="inner-banner alternate">
                  <div className="image-layer" style={{ background: `url(${Back5})`,backgroundRepeat: "no-repeat",   backgroundPosition: "0% 50%", backgroundSize: "100% 200%" }}></div>
        <div className="auto-container">
            <div className="inner">
                <div className="title-box">
                    <h1>Cloud Integration</h1>
                    <div className="d-text">Unify Your IT Ecosystem with Seamless Cloud Integration</div>
                </div>
            </div>
        </div>
              </section>
              <div class="sidebar-page-container services-page">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="content-side col-lg-8 col-md-12 col-sm-12">
                    <div class="services-content">
                        <div class="service-details">
                            <div class="image-box">
                                          <img src={require('../../images/DevOps Images/dev1.jpg')} alt="service-single-image"/>
                                      </div>
                                      <div className="content">
                                <h3 style={{ paddingLeft : '8px' }}> Cloud Integration</h3>
                                <div className="text">
                                    <p>
                                    In today's multi-cloud environment, the ability to connect disparate systems and applications is crucial for maintaining
                                     business efficiency and data consistency. At Kloudping IT Solution, our cloud integration services prioritize creating a cohesive 
                                     IT ecosystem where all your cloud and on-premises systems work in harmony. Our approach ensures that your business operations are streamlined, data is synchronized,
                                     and applications function seamlessly together     
                                    </p>
                                    
                                          </div>
                                          <div className="two-col">
                                    <div className="row clearfix">
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                                                      <div className="image"><img src={require('../../images/DevOps Images/dev2.jpg')} alt="featured-image-4"/></div>
                                        </div>
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                                                      <div className="image"><img src={require('../../images/DevOps Images/dev3.jpg')} alt="featured-image-5"/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text">
                                    

                                    <ul className="">
                                        <h3>Our Cloud Integration Priorities:</h3>
                                        {/* <li>Boat promises something for everyone that this groupfor everyone that this group.</li>
                                        <li>The old somehow form a family that's the way the Love Boat promis something for everyone that this group would somehow form.</li>
                                        <li>The old somehow form a family that's the way to morries.</li>  */}
                                    </ul>
                                   
                                </div>
                                <h2><b>Application Integration:</b></h2>
                                <p>
                                 We specialize in linking different software applications to function as a unified system,
                                  enhancing your business's ability to operate efficiently and adapt to changing needs. Our team 
                                  ensures that your applications communicate effectively, breaking down silos and enabling a seamless flow of information.
                                </p>
                                <h2><b>  Process Integration:</b></h2>
                                <p>
                                Streamlining workflows and business processes across various cloud environments is essential for operational efficiency.
                                 We focus on integrating processes across
                                 your IT landscape, enabling automated and cohesive workflows that enhance productivity 
                                 and reduce manual intervention.
                                </p>
                                <h2><b>Data Integration:</b></h2>
                                <p>
                                Consistent, real-time data is the backbone of informed decision-making. Our 
                                data integration services ensure that information is synchronized and shared between systems,
                                 eliminating data silos and ensuring that your teams have access to accurate and up-to-date information
                                  at all times.

                                </p>
                              <h2><b>Why Choose Us:</b></h2>
                              <p>
                             <ul>
                                <li>
                                ·   Expertise in integrating leading cloud platforms with on-premises systems
                                </li>
                                <li>
                                ·    Customized solutions that address your specific integration challenges

                                </li>
                                <li>
                                ·     A track record of delivering seamless integrations that enhance business operations

                                </li>
                             </ul>

</p>
                         <h2><b>Call to Action:</b></h2>
                         <p>
Ready to unify your IT environment? Contact us today to learn more about
 our cloud integration services and how we can help your business operate more efficiently.</p>
                            </div>
                           
                        </div>
                    </div>
                          </div>
                          <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside className="sidebar services-sidebar">
                
                        <div className="sidebar-widget services-widget">
                            <div className="widget-inner">
                                <ul>
                                    <li><a href='/Devops'>Devops</a></li>
                                    <li className="active"><a href='/cloudintegration'> Cloud Integration</a></li>
                                    <li><a href='/Migration'>Cloud Migration</a></li>
                                    <li><a href='/Operation'>Cloud Operation</a></li>
                                    <li><a href='/WebAppDevelopment'>Web/AppDevelopment</a></li>
                                </ul>
                            </div>
                        </div>
                        
                                  <div className="sidebar-widget info-widget">
                            <div className="widget-inner">
                                          <div className="image"><Link to="contact"><img src={require('../../images/DevOps Images/dev4.jpg')} alt="featured-image-4"/></Link></div>
                                <div className="lower">
                                    <div className="subtitle">Got any Questions? <br/> Call us Today!</div>
                                    <div className="icon-box"><span className="flaticon-telephone"></span></div>
                                    <div className="phone"><a href="tel:(+1)-8903500146">+91 8903500146</a></div>
                                    <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
                
            </div>
        </div>
    </div>
    <div className="theme-separator"></div>
                </>
                )
            }
        }
export default cloudintegration