import React , { Component }from 'react'
import Background7 from '../../images/background/services.jpg';
import Background8 from '../../images/background/image-3.jpg';
import Background9 from '../../images/background/image-2.jpg';
import OwlCarousel from 'react-owl-carousel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from "jquery";

class service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                name: '',
                phone: '',
                enquiryDate: '',
                email: '',
                subject: '',
                message: ''
            }
        };
    }

    
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };
    
    handleSubmit = async (e) => {
        e.preventDefault();
        const { formData } = this.state;

        try {
            const response = await fetch("https://dev-api-mysales.kloudping.com/api/Query/QueryEmail", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                toast.success("Message Sent Successfully!");
                this.setState({
                    formData: {
                        name: '',
                        phone: '',
                        enquiryDate: '',
                        email: '',
                        subject: '',
                        message: ''
                    }
                });
            } else {
                toast.error("Error sending message.");
            }
        } catch (error) {
            toast.error("Error sending message.");
        }
    };

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        this.setState({
            formData: {
                name: params.get('name') || '',
                phone: params.get('phone') || '',
                email: params.get('email') || '',
                subject: params.get('subject') || '',
                message: params.get('message') || '',
                enquiryDate: params.get('enquiryDate') || ''
            }
        });
        $(".txtOnly").bind("keypress", function (event) {
            if (event.charCode!=0) {
                var regex = new RegExp("^[a-zA-Z]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        });
        $(".numberOnly").bind("keypress", function (event) {
            if (event.charCode!=0) {
                var regex = new RegExp("^[0-9]+$");
                var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            }
        });
       }
       
    render() {
        const { formData } = this.state;
        return (
            <>
   <section className="inner-banner alternate">
        <div className="image-layer"  style={{ background: `url(${Background7})`,backgroundRepeat: "no-repeat",   backgroundPosition: "0% 55%", backgroundSize: "110% 220%"}}      ></div>
		<div className="auto-container">
            <div className="inner">
    			<div className="title-box">
                    <h1>Services </h1>
                    <div className="d-text">Services we offer</div>
                </div>
            </div>
		</div>
    </section>
    

    <section className="services-section">
        <div className="gradient-layer"></div>

        <div className="auto-container">
        <div className="sec-title centered">
                            <div className="upper-text">Kloudping - Services we offer</div>
                            
                        </div>

                        <div className="upper-row">
                            <div className="row clearfix">
                 
                                <div className="text-column col-lg-12 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="text">
                                            <p>At Kloudping, we're dedicated to transforming your digital aspirations into reality. 
                                                As a leading cloud services provider, we specialize in delivering exceptional software solutions that cater to the diverse needs of small to large businesses. 
                                                Our commitment to innovation, quality, and cutting-edge technologies has made us the go-to choice for businesses. 
                                           </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
               <div className="row clearfix">
                <div className="column col-lg-4 col-md-12 col-sm-12">
                    <div className="sec-title wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="upper-text" style={{ paddingLeft: '5px' }}>Kloudping IT Services</div>
                        <h2><strong style={{ paddingRight: '51px' }}>Solutions And</strong></h2>
                          <h2><strong style={{ paddingRight: '85px' }}> Focus Areas</strong></h2>
                        <div className="lower-text" style={{ paddingLeft: '5px' }}>Digital Transformation Through Cloud Implementation.</div>
                 </div>  
                    <div className="service-block wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="icon-outer">
                                <span className="icon-bg"></span>
                                <div className="icon-box"><img src={require('../../images/icons/services/1.png')}alt="services-1" title=""/></div>
                            </div>
                            <h3><a href='/datainfrastructure'>DevOps</a></h3>
                            <div className="text">At Kloudping IT Solutions, we committed to fostering collaboration and communication between your development and operations teams. 
                            Our mission is to automate manual processes, enhancing efficiency, reliability, and speed in software delivery. 
                            From Continuous Integration (CI) to Continuous Delivery/Deployment (CD), along with comprehensive Automation, Collaboration, Communication, and Testing, 
                            we ensure seamless DevOps services. Experience a transformative journey as we leverage our expertise to streamline processes and create a robust, efficient cloud environment. 
                            Welcome to a future where Kloudping IT Solutions makes your cloud aspirations a reality..</div>
                            <div className="more-link"><a href='/datainfrastructure'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>
                </div>
                <div className="column col-lg-4 col-md-12 col-sm-12">  
                    <div className="service-block wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="icon-outer">
                                <span className="icon-bg"></span>
                                <div className="icon-box"><img src={require('../../images/icons/services/2.png')}alt="services-2" title=""/></div>
                            </div>
                            <h3><a href='/cloudintegration'>IT Cloud Migration</a></h3>
                            <div className="text">Explore comprehensive cloud migration services encompassing infrastructure migration, data migration, application migration, and platform migration, 
                  all tailored for seamless optimization. Our expertise extends to executing successful cloud migrations, integrating careful planning, security considerations, 
                  and compliance requirements. With a commitment to ongoing management, we guarantee an optimized cloud environment that precisely meets the dynamic needs of your business. 
                  Trust us for a holistic approach to cloud migration, ensuring a smooth transition and enhanced operational efficiency.</div>
                            <div className="more-link"><a href='/cloudintegration'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>                    
                    <div className="service-block wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="icon-outer">
                                <span className="icon-bg"></span>
                                <div className="icon-box"><img src={require('../../images/icons/services/3.png')}alt="services-3" title=""/></div>
                            </div>
                            <h3><a href='/itstartup'>IT Cloud Intrgration</a></h3>
                            <div className="text"> Kloudping IT Solutions specializes in pragmatic cloud integration, connecting diverse software applications into a unified system for tailored functionality. 
                  Our expertise extends to streamlining workflows and business processes across various cloud environments, promoting operational efficiency. 
                  With a focus on secure data integration, we synchronize and share information seamlessly between systems, fostering collaboration and informed decision-making. 
                  Trust Kloudping for straightforward, expert solutions that seamlessly connect your applications, processes, and data.
                  </div>
                     <div className="more-link"><a href='/itstartup'><span className="fa fa-arrow-right"></span></a></div>
                    </div>
                    </div>
                </div>              
                <div className="column col-lg-4 col-md-12 col-sm-12">
                    
                    <div className="service-block wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="icon-outer">
                                <span className="icon-bg"></span>
                                <div className="icon-box"><img src={require('../../images/icons/services/4.png')}  alt="services-4" title=""/></div>
                            </div>
                            <h3><a href='/Operation'>Cloud Operation</a></h3>
                            <div className="text"> At Kloudping IT Solutions, we redefine Cloud Operations through cutting-edge automation and orchestration techniques. Our expert team excels in monitoring, deployment automation, and streamlining cloud operations to enhance efficiency. With a focus on precision, we orchestrate seamless workflows, ensuring a robust and responsive cloud infrastructure. From real-time monitoring automation to deploying efficient pipelines, we navigate the complexities of cloud deployments effortlessly. 
                                                   Kloudping is your trusted partner for unlocking the full potential of the cloud, optimizing operations, and driving excellence in every facet of your Cloud journey. {" "}</div>
                            <div className="more-link"><a href='/Operation'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div> 
                    <div className="service-block wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="icon-outer">
                                <span className="icon-bg"></span>
                                <div className="icon-box"><img src={require('../../images/icons/services/5.png')} alt="services-5" title=""/></div>
                            </div>
                            <h3><a href='/businesssecurity'>Web/App Development</a></h3>
                            <div className="text"> Thinking of launching a new project? We drive innovation through both website and app development, 
                  crafting seamless and engaging digital experiences. Our services cover everything from responsive website design 
                  and intuitive user interfaces to native and cross-platform app development. With experience in full-scale e-commerce projects, we handle front-end, back-end, and database needs.
                   Contact us to bring your vision to life!</div>
                            <div className="more-link"><a href='/businesssecurity'><span className="fa fa-arrow-right"></span></a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-text">
                <div className="text">We’re ready to discover and unlock your potential. <a href="/services"><strong>Get The Solutions</strong></a> or Call us Today! <a href="tel:+91 8903500146"><strong>+91 8903500146</strong></a></div>
            </div>
        </div>
    </section>
{/* 
    <section className="video-section alternate">
        <div className="image-layer" style={{ background: `url(${Background8})`}}   ></div>
        <div className="auto-container">
            <div className="video-link">
                <a href="https://youtu.be/6eCAGcYdKiw?si=QFrz010lMey3BKj8" className="theme-btn lightbox-image"><span className="flaticon-play-button"></span></a>
            </div>
            <h2>Kloudping IT Solutions will get you on the move towards your goals.</h2>
        </div>
    </section> */}

    <section className="services-section-three">

<div className="auto-container">
    <div className="sec-title centered">
        <div className="upper-text">IT Related  CaseStudies & Works</div>
        <h2><strong>Why We Stand Out</strong></h2>
    </div>
    <div className="services-box">
        <div className="row clearfix">
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-crown"></span></div>
                    </div>
                    <h3><a href='/#Services'>Comprehensive Expertise</a></h3>
                    <div className="text">Delivering end-to-end solutions in web and app development, cloud services, and DevOps with advanced technologies like Azure, AWS, Docker, and Kubernetes.</div>
                </div>
            </div>
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-bar-chart-alt"></span></div>
                    </div>
                    <h3><a href='/#Services'>Agile Methodology</a></h3>
                    <div className="text">Utilizing agile practices for flexibility, rapid delivery, and alignment with client goals, ensuring adaptable and optimized project outcomes.

 </div>
                </div>
            </div>
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-settings"></span></div>
                    </div>
                    <h3><a href='/#Services'>Talented Team</a></h3>
                    <div className="text">A skilled and experienced team proficient in the latest technologies and frameworks, including .NET Core, Angular, React, and Terraform, providing high-quality solutions.</div>
                </div>
            </div>
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-flag-alt"></span></div>
                    </div>
                    <h3><a href='/#Services'>Nationwide Deliverables</a></h3>
                    <div className="text">Providing both resources and technology across India, ensuring effective and accessible solutions no matter your location.</div>
                </div>
            </div>
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-world"></span></div>
                    </div>
                    <h3><a href='/#Services'>Proven Track Record </a></h3>
                    <div className="text">
                    Demonstrating reliability and success with a history of completed projects in diverse domains, including e-commerce and healthcare.</div>
                </div>
            </div>
          
            <div className="service-block-four col-lg-6 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box"><span className="themify-panel"></span></div>
                    </div>
                    <h3><a href='/#Services'>Custom Solutions</a></h3>
                    <div className="text">Offering tailored software solutions designed to fit specific business needs, from dynamic web platforms to robust mobile apps, ensuring performance and scalability</div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>

<div className="theme-separator"></div>



    <section className="testimonials-two">
        <div className="image-layer"  style={{ background: `url(${Background9})`}} ></div>

        <div className="auto-container">
            <div className="sec-title centered">
                <div className="upper-text">Read The Testimonials</div>
                <h2>What Clients Says <strong>About Us</strong></h2>
                <div className="lower-text">We take pride in delivering high-quality web and app development services that drive real results for businesses across various industries.</div>
            </div>

            <div className="carousel-box">
            <OwlCarousel className="testimonial-carousel theme-carousel owl-theme owl-carousel" loop="true" margin={30} autoheight="true" lazyload="true" nav= "true" dots= "true" autoplay= "true" autoplayTimeout= {5000} smartSpeed= {500}  items= {1}>
                   
                    <div className="testimonial-block">
                        <div className="inner">
                            <div className="content-box">
                                <div className="content">
                                    <div className="text">The HRMS transformed our HR operations. Automation of payroll, attendance, and performance evaluations has made our processes more efficient. The team was attentive to our needs and delivered exceptional results. </div>
                                </div>
                            </div>
                            
                            <div className="info">
                                <div className="image"><img src={require('../../images/resource/Teena.jpg')} alt="author-thumb-1"/></div>
                                <div className="name">Teena</div>
                                <div className="designation">HR Manager</div>
                                <div className="rating"><span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span></div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="testimonial-block">
                        <div className="inner">
                            <div className="content-box">
                                <div className="content">
                                    <div className="text">Our custom CRM tool from Kloudping has significantly improved our client management and lead generation. Their ability to tailor the solution to our specific requirements has been invaluable. </div>
                                </div>
                            </div>
                            
                            <div className="info">
                                <div className="image"><img src={require('../../images/resource/Gopal.png')} alt="author-thumb-2"/></div>
                                <div className="name">Gopal Krishna </div>
                                <div className="designation">Sales Director </div>
                                <div className="rating"><span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span></div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="testimonial-block">
                        <div className="inner">
                            <div className="content-box">
                                <div className="content">
                                    <div className="text">Led Ai has greatly enhanced our sales processes by automating tasks and centralizing employee records, making data management more efficient. The dedicated Led Ai manager provided excellent support during implementation, leading to improved customer relationship management.</div>
                                </div>
                            </div>
                            
                            <div className="info">
                                <div className="image"><img src={require('../../images/resource/gautham.PNG')} alt="author-thumb-3"/></div>
                                <div className="name">Gowthaman</div>
                                <div className="designation">Technical</div>
                                <div className="rating"><span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span></div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-block">
                        <div className="inner">
                            <div className="content-box">
                                <div className="content">
                                    <div className="text">Kloudping’s team delivered a top-notch e-commerce app that exceeded our expectations. Their expertise in development and seamless integration with our existing systems was impressive. Highly recommend!  </div>
                                </div>
                            </div>
                            
                            <div className="info">
                                <div className="image"><img src={require('../../images/resource/th.jpg')} alt="author-thumb-3"/></div>
                                <div className="name">Vincent Scott</div>
                                <div className="designation">Technical</div>
                                <div className="rating"><span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span> <span className="fa fa-star"></span></div>
                            </div>
                        </div>
                    </div>

                </OwlCarousel>
            </div>
        </div>

    </section>
<br/>
    <section className="contact-section">
        <div className="map-pattern-layer"></div>

        <div className="auto-container">
            <div className="row clearfix">
               
                <div className="text-column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                        <div className="sec-title" style={{marginTop: '-39px'}}>
                            <div style={{ paddingLeft: '165px' }}>Get In Touch With Us Today!</div>
                            <h2><strong>Need Our Services? <br/>Book Your Appointment</strong></h2>
                            <div className="lower-text" style={{ paddingLeft: '80px' }}>We Help Customers Digital Transformation By IT Solutions</div>
                        </div>

                        <ul className="info">
                            <li><div className="phone-title"style={{ paddingLeft: '170px' }}>call us for support</div></li>
                            <li className="phone"><a href="tel:+91 8903500146"><span className=""></span> <strong style={{marginLeft: '-4px',paddingLeft:'175px'}}>+91 89035 00146</strong></a></li>
                            <li className="email"><a href="mailto:support@loudping.com"><strong style={{ paddingLeft: '165px' }}>info@kloudping.com</strong></a></li>
                        </ul>
                    </div>
                </div>
               
                <div className="form-column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                        
                        <div className="form-box">
                            <div className="default-form appointment-form">
                            <form className="default-form contact-form" onSubmit={this.handleSubmit}>
                                                    <div className="row clearfix">
                                                        <h2>Contact Us</h2>
                                                        <p>Fill out the form and we'll be in touch soon!</p>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                                                            <input
                                                                id="name"
                                                                type="text"
                                                                name="name"
                                                                placeholder="Your Name"
                                                                value={formData.name}
                                                                onChange={this.handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                            <input
                                                                id="email"
                                                                type="email"
                                                                name="email"
                                                                placeholder="Email"
                                                                value={formData.email}
                                                                onChange={this.handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group numberOnly">
                                                            <input
                                                                id="phone"
                                                                type="text"
                                                                name="phone"
                                                                placeholder="Phone"
                                                                value={formData.phone}
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6 col-sm-12" >
                                                            <select
                                                                className="custom-select-box"
                                                                name="subject"
                                                                value={formData.subject}
                                                                onChange={this.handleInputChange}
                                                                style={{ color: formData.subject ? 'black' : '#aaaaaa' }}
                                                            >
                                                                <option value="" disabled>Enquiry About</option>
                                                                <option value="Devops">Devops</option>
                                                                <option value="Cloud Migration">Cloud Migration</option>
                                                                <option value="IT Cloud Integration">IT Cloud Integration</option>
                                                                <option value="Cloud Operation">Cloud Operation</option>
                                                                <option value="Mobile/app Development">Mobile/app Development</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                            <input
                                                                id="enquiryDate"
                                                                type="date"
                                                                name="enquiryDate"
                                                                placeholder="Date"
                                                                value={formData.enquiryDate}
                                                                onChange={this.handleInputChange}
                                                                style={{ color: formData.enquiryDate ? 'black' : '#aaaaaa' }}
                                                            />
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                            <textarea
                                                                id="message"
                                                                name="message"
                                                                placeholder="Message"
                                                                value={formData.message}
                                                                onChange={this.handleInputChange}
                                                            ></textarea>
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-12">
                                                            <button type="submit" className="theme-btn btn-style-one">
                                                                <span className="btn-title">Make a Request</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                     </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>

            </>
        )
    }
}
export default service
