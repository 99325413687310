import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import kloudpingIcon from '../../images/kloudpingicon.png'; // Ensure this path is correct

export default function Pop({ open, onClose }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const email = formJson.email;
          console.log(email);
          handleClose();
        },
        sx: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          margin: 'auto',
          width: '480px',
          padding: '20px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
          borderRadius: '40px',
          color: '#fff',
          backgroundImage: 'linear-gradient(0deg, rgba(10,13,97,1) 55%, rgba(250,250,250,1) 100%)',
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '30px', color: '#f3f4f8' }}>
        <img
          src={kloudpingIcon}
          alt="Kloudping Icon"
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            marginBottom: '20px',
          }}
        />
        <br />
        Get a 1-Month Free DevOps Consultation
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: '17px', marginLeft: '20px', fontSize: '15px', color: '#f3f4f8' }}>
          Hire, collaborate, and partner with us to optimize your processes and succeed.
          With our industry experts, experience quality DevOps services. Let’s make it happen together.
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          type="email"
          placeholder="Email Address"
          fullWidth
          variant="outlined"
          sx={{
            backgroundColor: '#fff',
            borderRadius: '5px',
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingBottom: '10px' }}>
        <Button
          type="submit"
          sx={{
            color: '#fff',
            backgroundColor: '#FF5C93',
            '&:hover': {
              backgroundColor: '#FF3366',
            },
            padding: '10px 20px',
            borderRadius: '5px',
          }}
        >
          Subscribe
        </Button>
        <Button
          onClick={handleClose}
          sx={{
            color: '#fff',
            backgroundColor: '#FF5C93',
            '&:hover': {
              backgroundColor: '#FF3366',
            },
            padding: '10px 20px',
            borderRadius: '5px',
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
