import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $, { data } from "jquery"
export default function StartSlow(props) {
   
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        query: '',
        email: '',
      });
    
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
    
        // Create a model object with the form data
        const model = {
          name: formData.name,
          phone: formData.phone,
          message: formData.query,
          email: formData.email,
          subject:"query"
        };

        // Send a POST request to the server using $.ajax
        $.ajax({
          // url: 'https://localhost:44351/api/Query/QueryEmail',
          url: 'https://dev-api-mysales.kloudping.com/api/Query/QueryEmail',
          type: 'POST',
          data: JSON.stringify(model),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          success: function (data) {
        
            alert('Message Sent Successfully!');
          },
          error: function () {
            toast.success('Thank You! Email Received!!');
          },
        });
        
 
  };
  const preference = (preference) => {
    const { name, age } = props.state.userData;
    const category = props.state.userData.category;
    const product = props.state.data[category][preference];
    props.state.userData.product = product;
    props.actions.finalResult(name, age, preference, product.name);
    
}
  return (
    <div>
      <form onSubmit={handleSubmit} className='botform'>
        <label>
          Name:
          <input className='bot'
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Phone:
          <input className='bot'
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Query:
          <input className='bot'
            type="text"
            name="query"
            value={formData.query}
            onChange={handleInputChange}
            required
          />
          
        </label>
        <label>
          Email:<br/>
          <input className='bot'
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </label>
        <button type="submit" className="theme-btn btn-style-one" onClick={() => preference("development")} ><span className="btn-title" >Make a Query</span></button>
      </form>
      <ToastContainer />
    </div>
  );
}




