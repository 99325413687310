import React,{ Component } from "react";
import Background from "../../images/background/image-1.jpg";
import Background2 from "../../images/background/image-2.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import WOW from "wowjs";
import CountUp from "react-countup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import Contact from "../contact/contact";
import 'react-chatbot-kit/build/main.css'
import Pop from "../chatbot/Pop";
import {Link} from 'react-router-dom'
import './../../css/style.css'
import Windowpop from "../chatbot/Windowpop";
// import Contact from "../contact/contact";

class home extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        formData: {
            name: '',
            phone: '',
            enquiryDate: '',
            email: '',
            subject: '',
            message: ''
        }
    };
}

handleInputChange = (e) => {
  const { name, value } = e.target;
  this.setState(prevState => ({
      formData: {
          ...prevState.formData,
          [name]: value
      }
  }));
};

handleSubmit = async (e) => {
  e.preventDefault();
  const { formData } = this.state;
  

  try {
      const response = await fetch("https://dev-api-mysales.kloudping.com/api/Query/QueryEmail", {
          method: "POST",
          headers: {
              "Content-Type": "application/json; charset=utf-8"
          },
          body: JSON.stringify(formData)
      });

      if (response.ok) {
          toast.success("Message Sent Successfully!");
          this.setState({
              formData: {
                  name: '',
                  phone: '',
                  enquiryDate: '',
                  email: '',
                  subject: '',
                  message: ''
              }
          });
      } else {
          toast.error("Error sending message.");
      }
  } catch (error) {
      toast.error("Error sending message.");
  }
};

componentDidMount() {
  const params = new URLSearchParams(window.location.search);
        this.setState({
            formData: {
                name: params.get('name') || '',
                phone: params.get('phone') || '',
                email: params.get('email') || '',
                subject: params.get('subject') || '',
                message: params.get('message') || '',
                enquiryDate: params.get('enquiryDate') || ''
            }
        });

  $(".txtOnly").bind("keypress", function (event) {
    if (event.charCode != 0) {
      var regex = new RegExp("^[a-zA-Z]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    }
  });
  $(".numberOnly").bind("keypress", function (event) {
    if (event.charCode != 0) {
      var regex = new RegExp("^[0-9]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    }
  });
  new WOW.WOW({
    live: false,
  }).init();

  setTimeout(() => {
    $("body").addClass("page-loaded");
    setTimeout(() => {
      $(".preloader").delay(500).fadeOut(0);
    }, 1000);
  }, 500);
}


render() {
  const { formData } = this.state;
  return (
    <>
    <Windowpop />
      <div className="preloader">
        <div className="icon"></div>
      </div>

      <section className="banner-section banner-one">
        <div className="banner-curve"></div>
       
  
        <OwlCarousel
          className="banner-carousel  theme-owl-theme  owl-carousel"
          loop="true"
          margin={0}
          autoheight="true"
          lazyload="true"
          nav="true"    
          dots="true"
          autoplay="true"
          autoplayTimeout={6000}
          smartSpeed={300}
          items={1}
        >
          <div className="slide-item">
            <div className="auto-container">
              <div className="content-box">
                <div className="round-layer"></div>

                <div className="content">
                  <div className="inner">
                    <div className="sub-title">
                    All Cloud Solutions for Easy Integration
                    </div>
                    {/* <h2>
                      Deliver <strong>Innovative IT Solutions</strong> For
                      Technology Insights
                    </h2> */}
                    <div className="text1">
                    <b><span >DRIVE GROWTH WITH DEVOPS, DYNAMIC CLOUD, AND DIGITAL SOFTWARE SOLUTIONS.</span></b>
                    </div>
                    <div className="links-box">
                      <Link to="about" className="theme-btn btn-style-one">
                        <div className="btn-title">More Details</div>
                      </Link>
                     <Link to="/services"
                        className="theme-btn btn-style-one"
                      >
                        <div className="btn-title">Expert Case Study</div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="content-image">
                <img
                    src={require("../../images/App-development.png")}
                    alt="content-image-1"
                    title=""
                    style={{ marginTop: '15%',paddingLeft: '12%'}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="slide-item">
            <div className="auto-container">
              <div className="content-box">
                <div className="round-layer"></div>

                <div className="content">
                  <div className="inner alternate">
                    <div className="sub-title">
                    All Cloud Solutions for Easy Integration
                    </div>
                    {/* <h2>
                      <strong>IT Services</strong> To Develop Solutions Your
                      Way!
                    </h2> */}
                    <div className="text1">
                    <b><span >DRIVE GROWTH WITH DEVOPS, DYNAMIC CLOUD, AND DIGITAL SOFTWARE SOLUTIONS.</span></b>
                    </div>
                    <div className="links-box">
                      <Link to="about" className="theme-btn btn-style-one">
                        <div className="btn-title">More Details</div>
                      </Link>
                     <Link to="/services"
                        className="theme-btn btn-style-one"
                      >
                        <div className="btn-title">Expert Case Study</div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="content-image">
                   <img
                    src={require("../../images/cloud12.png")}
                    alt="content-image-1"
                    title=""
                    style={{ marginTop: '20%',paddingLeft: '15%'}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="slide-item">
            <div className="auto-container">
              <div className="content-box">
                <div className="round-layer"></div>

                <div className="content">
                  <div className="inner">
                    <div className="sub-title">
                    All Cloud Solutions for Easy Integration
                    </div>
                    {/* <h2>
                      Deliver <strong>Innovative IT Solutions</strong> For
                      Technology Insights
                    </h2> */}
                    <div className="text1">
                    <b><span >DRIVE GROWTH WITH DEVOPS, DYNAMIC CLOUD, AND DIGITAL SOFTWARE SOLUTIONS.</span></b>
                    </div>
                    <div className="links-box">
                      <Link to="about" className="theme-btn btn-style-one">
                        <div className="btn-title">More Details</div>
                      </Link>
                     <Link to="/services"
                        className="theme-btn btn-style-one"
                      >
                        <div className="btn-title">Expert Case Study</div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="content-image">
                  <img
                    src={require("../../images/App-development.png")}
                    alt="content-image-1"
                    title=""
                    style={{ marginTop: '15%',paddingLeft: '12%'}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="slide-item">
            <div className="auto-container">
              <div className="content-box">
                <div className="round-layer"></div>

                <div className="content">
                  <div className="inner alternate">
                    <div className="sub-title">
                    All Cloud Solutions for Easy Integration
                    </div>
                    {/* <h2>
                      <strong>IT Services</strong> To Develop Solutions Your
                      Way!
                    </h2> */}
                  
                    <div className="text1">
                    <b><span >DRIVE GROWTH WITH DEVOPS, DYNAMIC CLOUD, AND DIGITAL SOFTWARE SOLUTIONS.</span></b>
                    </div>
                    <div className="links-box">
                      <Link to="about" className="theme-btn btn-style-one">
                        <div className="btn-title">More Details</div>
                      </Link>
                     <Link to="/services"
                        className="theme-btn btn-style-one"
                      >
                        <div className="btn-title">Expert Case Study</div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="content-image">
                <img
                    src={require("../../images/cloud12.png")}
                    alt="content-image-1"
                    title=""
                    style={{ marginTop: '20%',paddingLeft: '15%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
     
      </section>

      <section className="about-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="text-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="sec-title">
                  <div className="upper-text"style={{ paddingLeft: '4px' }}>
                    Welcome to Kloudping IT Solution
                  </div>
                  <h2>
                    <strong style={{ paddingLeft: '2px' }}>Your Preferred IT Partner</strong>
                  </h2>
                </div>
                   <div> <Pop /> </div>
                <div className="text-content">
                  {/* <p>

                  Welcome to our world of cloud excellence! With a proven track record of successfully completing numerous cloud projects, 
                  we take pride in our expert team dedicated to delivering top-notch solutions. Harness the experience of a team that has mastered the intricacies of cloud technology. 
                  Our journey is defined by successful projects and satisfied clients. Trust us to bring that expertise to your doorstep.

                  </p> */}
                  <p style={{ paddingRight: '15px' }}>
                  At Kloudping, we excel in delivering top-tier DevOps and cloud solutions that enhance operations and scalability. From legacy system deployments and infrastructure management to cloud migration and integration, we’ve successfully managed diverse projects. Our expertise also includes web and app development, such as eCommerce platforms, custom CRMs, and product development.
                 </p>
                 <p style={{ paddingRight: '15px' }}>
                  Our services cover DevOps & CI/CD pipeline management, legacy deployments, application modernization, performance & go-live support, as well as Terraform, Docker, Kubernetes, Ansible, Jenkins. We also offer cloud migration & integration, backup & disaster recovery, and remote infrastructure management. Whether you’re a startup or an enterprise, Kloudping provides innovative IT solutions to drive growth in the digital age.
                 </p>
                  {/* <ul className="list-style-one">
                    <li>We are committed to providing quality IT Services</li>
                    <li>
                      Our benefits are endless for local IT Companies &
                      Startups
                    </li>
                    <li>
                      Really know the true needs and expectations of customers
                    </li>
                    <li>
                      Talented & experienced management solutions for IT
                    </li>
                    <li>
                      Processes of achieving the excellence and continue
                      improvements
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>

            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="images">
                  <div className="row clearfix" style={{marginTop:'18px'}}>
                    <figure
                      className="image col-md-6 col-sm-6 wow lefttop"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <img 
                        src={require("../../images/resource/alter1.jpeg")}
                        alt="about-image-1"
                        title=""
                       
                      />
                    </figure>
                    <figure
                      className="image col-md-6 col-sm-6 wow fadeInRight"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <img
                        src={require("../../images/resource/alter2.jpeg")}
                        alt="about-image-2"
                        title=""
                      />
                    </figure>
                    <figure
                      className="image col-md-6 col-sm-6 wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                     
                    >
                      <img
                        src={require("../../images/resource/alter3.png")}
                        alt="about-image-3"
                        title=""
                        
                      /> 
                    </figure>
                    <figure
                      className="image col-md-6 col-sm-6 wow fadeInRight"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
               
                    >
                      <img
                        src={require("../../images/resource/alter4.png")}
                        alt="about-image-4"
                        title=""
              
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="theme-separator"></div>

      <section className="services-section">
        <div className="gradient-layer"></div>
        <div className="pattern-layer"></div>

        <div className="auto-container">
          <div className="row clearfix">
            <div className="col col-lg-4 col-md-12 col-sm-12">
              <div
                className="sec-title wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
              <div className="upper-text" style={{ paddingLeft: '6px' }}>Kloudping IT Services</div>
                <h2>
                  <strong style={{ paddingRight: '61px' }}>Solutions And</strong>
                </h2>
                <h2>
                <strong style={{ paddingRight: '95px' }}> Focus Areas</strong>
                </h2>
                <div className="lower-text" style={{ paddingLeft: '5px' }}>
                  Digital Transformation Through Cloud Implementation.
                </div>
              </div>
              <div
                className="service-block wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="icon-outer">
                    <span className="icon-bg"></span>
                    <div className="icon-box">
                      <img
                        src={require("../../images/icons/services/1.png")}
                        alt="services-3"
                        title=""
                      />
                    </div>
                  </div>
                  <h3>
                  <Link to="Devops">DevOps</Link>
                  </h3>
                  <div className="text">
                  At Kloudping IT Solutions, we believe innovation begins with collaboration.
                  Our DevOps services bridge the gap between development and operations, empowering your team to deliver software
                  with speed, reliability, and agility. With experienced DevOps professionals, we specialize in CI/CD pipelines,
                  Infrastructure as Code, Monitoring & Logging, Cloud Solutions, and more.
                  </div>

                  <div className="more-link">
                  <Link to="Devops">
                      <span className="fa fa-arrow-right"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            


            <div className= "service-blocks col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-block wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="icon-outer">
                    <span className="icon-bg"></span>
                    <div className="icon-box">
                      <img
                        src={require("../../images/icons/services/4.png")}
                        alt="services-4"
                        title=""
                      />
                    </div>
                  </div>

                  <h3>
                    <Link to="Operation">Cloud Computing</Link>
                  </h3>
                  <div className="text">
                  Ready to elevate your business with cloud solutions? We specialize in cloud computing to enhance scalability,
                   flexibility, and efficiency. Our services cover cloud migration, integration, operations, infrastructure management, 
                   and optimized resource allocation, ensuring your systems run smoothly and securely. With our expertise, you can harness the power of the cloud to boost your operations and drive growth. Reach out
                   to discover how cloud computing can transform your business!
                  </div>
                  <div className="more-link">
                    <Link to="Operation">
                      <span className="fa fa-arrow-right"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className= "service-blocks col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-block wow fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <div className="icon-outer">
                    <span className="icon-bg"></span>
                    <div className="icon-box">
                      <img
                        src={require("../../images/icons/services/5.png")}
                        alt="services-5"
                        title=""
                      />
                    </div>
                  </div>
                  <h3>
                    <Link to="WebAppDevelopment">Web/App Development</Link>
                  </h3>
                  <div className="text">
                  Thinking of launching a new project? We drive innovation through both website and app development, 
                  crafting seamless and engaging digital experiences. Our services cover everything from responsive website design 
                  and intuitive user interfaces to native and cross-platform app development. With experience in full-scale e-commerce projects, we handle front-end, back-end, and database needs.
                   Contact us to bring your vision to life!
                  </div>
                  <div className="more-link">
                    <Link to="businesssecurity">
                      <span className="fa fa-arrow-right"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-text">
            <div className="text">
              We’re ready to discover and unlock your potential.{" "}
              <Link>
              <Link to="/contact" >
                <strong>Get The Solutions</strong>
              </Link></Link>{" "}
              or Call us Today!{" "}
              <a href="tel: +91 89035 00146">
                <strong> +91 89035 00146</strong>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="locations-section">
        <div className="auto-container">
          <div className="sec-title centered">
            <div className="upper-text">Kloudping IT Services</div>
            <h2>Kloudping Delivering World-Class Projects to Every Corner of the Globe</h2>
            <p>With Over 18+ Successful Projects Delivered Globally, Kloudping 
            Sets the Standard for Excellence.</p>

            {/* <div className="lower-text">
              We value customer experience as a universal standard and we adapt to the local needs and preferences of each market.
              {" "}
            </div> */}
          </div>
          <div className="map-box">
            <div className="map-image">
              <img
                src={require("../../images/icons/map-pattern.png")}
                alt="map-pattern"
                title=""
              />
            </div>

            <div className="location-point loc-one">
              <div className="inner">
                4+ Projects in  TamilNadu , India
              </div>
            </div>

            <div className="location-point loc-two">
              <div className="inner">
                4+ Projects in Maharashtra , India
              </div>
            </div>

            <div className="location-point loc-three">
              <div className="inner">
                2+ Projects in USA
              </div>
            </div>

            <div className="location-point loc-four">
              <div className="inner">
                5+ Projects in Odisha , India
              </div>
            </div>

            <div className="location-point loc-five">
            {/* <div className="icon-outer"> */}
            {/* <img 
              style={{ width: '200px',height:'100px' } }
                src={require("../../images/flags/mal1.jpg")}
                alt="malaysia"
                title=""
              /> */}
            {/* </div> */}
              <div className="inner">
                2+ Projects in  Malaysia
              </div>
            </div>
            <div className="location-point loc-six">
              <div className="inner">
                2+ Projects in  Hyderabad , India
              </div>
            </div>

            {/*-- <div className="location-point loc-five">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              <div className="location-point loc-six">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              <div className="location-point loc-seven">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              <div className="location-point loc-eight">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              
              
             <div className="location-point loc-nine">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>

              
              <div className="location-point loc-ten">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              <div className="location-point loc-eleven">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div>
              
              <div className="location-point loc-twelve">
                  <div className="inner">100+ Projects Done For Local IT Companies</div>
              </div> */}
          </div>
        </div>
      </section>

      {/* <section className="default-bg-section">
        <div
          className="image-layer"
          style={{ background: `url(${Background})` }}
        ></div>

        <div className="auto-container">
          <div className="content-box">
            <h2>
              Our Cloud Solutions will get you on move towards your destination
              faster than rivals in a more reliable way!
            </h2>
            <div className="link-box">
              <Link to="services" className="theme-btn btn-style-one">
                <div className="btn-title">Proven IT Solutions</div>
              </Link>
            </div>
          </div>
        </div>
      </section> */}

      <section className="fun-facts-section">
        <div className="auto-container">
          <div className="fact-counter">
            <div className="row clearfix">
              <div className="counter-column col-lg-4 col-md-4 col-sm-12">
                <div className="inner wow fadeInUp" data-wow-delay="0ms">
                  <div className="upper-title">Projects Worked On</div>
                  <div className="count-box">
                    <CountUp
                      className="count-text"
                      end={20}
                      enableScrollSpy="true"
                      scrollSpyOnce="true"
                    >
                      0
                    </CountUp>
                    +
                  </div>
                  <div className="counter-title">for customers</div>
                </div>
              </div>

              <div className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp">
                <div className="inner wow fadeInUp" data-wow-delay="300ms">
                  <div className="upper-title">Powerful Team to Focus</div>
                  <div className="count-box">
                    <CountUp
                      className="count-text"
                      end={15}
                      enableScrollSpy="true"
                      scrollSpyOnce="true"
                    >
                      0
                    </CountUp>
                    +
                  </div>
                  <div className="counter-title">Expert Members</div>
                </div>
              </div>

              <div className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp">
                <div className="inner wow fadeInUp" data-wow-delay="600ms">
                  <div className="upper-title">Launched Case Studies</div>
                  <div className="count-box">
                    <CountUp
                      className="count-text"
                      end={3}
                      enableScrollSpy="true"
                      scrollSpyOnce="true"
                    >
                      0
                    </CountUp>
                  </div>
                  <div className="counter-title">Fully Launched</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="theme-separator"></div>

      {/* <section className="cases-section">
        <div className="gradient-layer"></div>

        <div className="auto-container">
          <div className="sec-title centered">
            <div className="upper-text">Our Case Studies</div>
            <h2>
              <strong>IT Solutions & Projects</strong>
            </h2>
            <div className="lower-text">
              Cloud Computing is responding well to the changing needs of the
              current times. Those needs are either be of businesses or
              educational institutions like colleges, schools which have to be
              fulfilled with cost-effective and scalable solutions.
            </div>
          </div>

          <div className="carousel-box">
            <OwlCarousel
              className="cases-carousel theme-carousel owl-theme owl-carousel"
              loop="true"
              margin={30}
              autoheight="true"
              lazyload="true"
              nav="true"
              dots={false}
              autoplay="true"
              autoplayTimeout={7000}
              smartSpeed={700}
              items={2}
            >
              <div className="slide-item">
                <div className="case-block">
                  <div className="inner-box">
                    <figure className="image-box">
                      <Link to="casestudies">
                        <img
                          src={require("../../images/resource/case-image-1.webp")}
                          alt="case-image-1"
                          title=""
                        />
                      </Link>
                    </figure>
                    <div className="content-box">
                      <div className="title-box">
                        <h4>
                          <Link to="casestudies">Workflow Management</Link>
                        </h4>
                        <div className="sub-text">IT Networking</div>
                      </div>
                      <div className="text-content">

                        <Link to="casestudies">Workflow Management</Link>

                        <div className="text">
                          Workflow management is the discipline of creating,
                          documenting,monitoring and improving upon theseries of steps, or workflow, that is required to
                          complete a specific task.{" "}
                        </div>
                        <div className="link-box">
                          <Link to="casestudies">
                            View Case Study{" "}
                            <span className="arrow fa fa-arrow-right"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="slide-item">
                <div className="case-block">
                  <div className="inner-box">
                    <figure className="image-box">
                      <Link to="casestudies">
                        <img
                          src={require("../../images/resource/case-image-1.webp")}
                          alt="case-image-1"
                          title=""
                        />
                      </Link>
                    </figure>
                    <div className="content-box">
                      <div className="title-box">
                        <h4>
                          <Link to="casestudies">Data MachineLearning</Link>
                        </h4>
                        <div className="sub-text"> Artificial Intelligence</div>
                      </div>
                      <div className="text-content">

                        <Link to="casestudies">Data Machine Learning</Link>

                        <div className="text">
                          Data cloud is a crucial component in the field of
                          Machine Learning.It refers to the set of
                          observations or measurements that can be used to
                          train a machine-learning model.{" "}
                        </div>
                        <div className="link-box">
                          <Link to="casestudies">
                            View Case Study{" "}
                            <span className="arrow fa fa-arrow-right"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="slide-item">
                <div className="case-block">
                  <div className="inner-box">
                    <figure className="image-box">
                      <Link to="casestudies">
                        <img
                          src={require("../../images/resource/case-image-1.webp")}
                          alt="case-image-1"
                          title=""
                        />
                      </Link>
                    </figure>
                    <div className="content-box">
                      <div className="title-box">
                        <h4>
                          <Link to="casestudies">Workflow Management</Link>
                        </h4>
                        <div className="sub-text">IT Networking</div>
                      </div>
                      <div className="text-content">
                        <Link to="casestudies">Workflow Management</Link>
                        <div className="text">
                          Workflow management is the discipline of creating,
                          documenting, monitoring and improving upon the
                          series of steps, or workflow, that is required to
                          complete a specific task.

                        </div>
                        <div className="link-box">
                          <Link to="casestudies">
                            View Case Study{" "}
                            <span className="arrow fa fa-arrow-right"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="slide-item">
                <div className="case-block">
                  <div className="inner-box">
                    <figure className="image-box">
                      <Link to="casestudies">
                        <img
                          src={require("../../images/resource/case-image-2.jpg")}
                          alt="case-image-2"
                          title=""
                        />
                      </Link>
                    </figure>
                    <div className="content-box">
                      <div className="title-box">
                        <h4>
                          <Link to="casestudies">Data Machine Learning</Link>
                        </h4>
                        <div className="sub-text">
                          Artificial Intelligence
                        </div>
                      </div>
                      <div className="text-content">

                        <Link to="casestudies">Data Machine Learning</Link>

                        <div className="text">
                          Data cloud is a crucial component in the field of
                          Machine Learning.It refers to the set of
                          observations or measurements that can be used to
                          train a machine-learning model.
                        </div>
                        <div className="link-box">
                          <Link to="casestudies">
                            View Case Study{" "}
                            <span className="arrow fa fa-arrow-right"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>

          <div className="bottom-text">
            <div className="text">
              We’re ready to discover and unlock your potential.{" "}
              <strong>Call us Today!</strong> &nbsp;
              <a href="tel: +91 89035 00146">
                <strong> +91 89035 00146</strong>
              </a>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="appointment-section"> */}
        {/* <div className="pattern-layer"></div>

        <div className="auto-container">
          <div className="row clearfix">
            <div className="text-column col-lg-6 col-md-12 col-sm-12" style={{ marginTop: '-5%' }}>
              <div className="inner">
                <div className="sec-title">
                  <div className="line" >
                    Kloudping - Welcome to IT Solutions
                  </div>
                  <h2>
                    Why Choose <strong> Kloudping IT Solutions</strong>
                  </h2>
                  <div className="lower-text">
                    gives you access to top-notch IT administrators with
                    specific skill sets, negating the need to find and hire
                    them yourself.
                  </div>
                </div>

                <div className="content ">
                  <div className="service-block-two">
                    <div className="inner-box">
                      <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box">
                          <img
                            src={require("../../images/icons/services/6.png")}
                            alt="services-6"
                            title=""
                          />
                        </div>
                      </div>
                      <h3>
                        <Link to="services">Easier To Implement</Link>
                      </h3>
                      <div className="text">
                        the strategic approach to helping an organization
                        adopt new procedures
                      </div>
                    </div>
                  </div>

                  <div className="service-block-two">
                    <div className="inner-box">
                      <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box">
                          <img
                            src={require("../../images/icons/services/7.png")}
                            alt="services-7"
                            title=""
                          />
                        </div>
                      </div>
                      <h3>
                        <Link to="services">Increase Growth Rates</Link>
                      </h3>
                      <div className="text">
                        measures its change from one period to another (month,
                        quarter, year). It is very generally expressed as a
                        percentage
                      </div>
                    </div>
                  </div>

                  <div className="service-block-two">
                    <div className="inner-box">
                      <div className="icon-outer">
                        <span className="icon-bg"></span>
                        <div className="icon-box">
                          <img
                            src={require("../../images/icons/services/8.png")}
                            alt="services-8"
                            title=""
                          />
                        </div>
                      </div>
                      <h3>
                        <Link to="services">Branding Of The Future</Link>
                      </h3>
                      <div className="text">
                        Branding Future is a venture engaged in the business
                        of providing services to businesses such as Branding,
                        Marketing, Public Relations as well as Cloud
                        Management.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-column col-lg-6 col-md-12 col-sm-12">
              <div
                className="inner wow fadeInRight"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <figure className="image">
                  <img
                    src={require("../../images/resource/featured-image-1.jpg")}
                    alt="featured-image-1"
                    title=""
                  />
                </figure>

                <div className="form-box">
                  <div className="title">
                    <h3>Book Your Appointment</h3>
                    <div className="text" style={{color:'white'}} >
                      We Help Customers Digital Transformation By IT Solutions
                    </div>
                  </div>
                  <div className="default-form appointment-form">
                  <form onSubmit={this.handleSubmit}>
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                          <input
                            type="text"
                            name="name"
                            placeholder="First Name"
                            value={formData.name}
                            onChange={this.handleChange}
                            required=""
                            // defaultValue=""
                          />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={this.handleChange}
                            required=""
                            // defaultValue=""
                          />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={this.handleChange}
                            required=""
                            // defaultValue=""
                          />
                        </div>

                        <div className="form-group col-md-6 col-sm-12">
                          <select
                            className="custom-select-box"
                            name="subject"
                            value={formData.subject}
                            onChange={this.handleChange}
                          >
                            <option>Enquiry About</option>
                            <option>Devops</option>
                            <option>Cloud Migration</option>
                            <option>IT Cloud Integration</option>
                            <option>Cloud Operation</option>
                            <option>Mobile/app Development</option>
                          </select>
                        </div>

                        <div className="form-group col-md-12 col-sm-12">
                          <Link to={{pathname: "/contact",state: { formData }, }} 
                          className="theme-btn btn-style-one" >

                            <span className="btn-title"> Make a Request</span>
                          </Link>
                        </div>
                        <div className="form-group col-md-12 col-sm-12">
                          <button type="submit" className="theme-btn btn-style-one">
                            <span className="btn-title">Make a Request</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
       <section className="contact-section">
    <div className="map-pattern-layer"></div>
    <div className="auto-container">
        <div className="row clearfix">
            <div className="text-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner">
                    <div className="sec-title" style={{marginTop: '-39px'}}>
                        <div style={{ paddingLeft: '165px' }}>Get In Touch With Us Today!</div>
                        <h2><strong>Need Our Services? <br/>Book Your Appointment</strong></h2>
                        <div className="lower-text" style={{ paddingLeft: '80px' }}>We Help Customers Digital Transformation By IT Solutions</div>
                    </div>

                    <ul className="info">
                        <li><div className="phone-title"style={{ paddingLeft: '170px' }}>call us for support</div></li>
                        <li className="phone">
                            <a href="tel:+91 8903500146">
                                <strong style={{marginLeft: '-4px',paddingLeft:'175px'}}>+91 89035 00146</strong>
                            </a>
                        </li>
                        <li className="email">
                            <a href="mailto:support@loudping.com"><strong style={{ paddingLeft: '165px' }}>info@kloudping.com</strong></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="form-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner">
                    <div className="form-box">
                        <div className="default-form appointment-form">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row clearfix">
                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                                    <input
                                      id="name"
                                      type="text"
                                      name="name"
                                      placeholder="Name"
                                      value={formData.name}
                                      onChange={this.handleInputChange}
                                      required
                                    />
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                                    <input
                                      id="email"
                                      type="email"                          
                                      name="email"                          
                                      placeholder="Email"                          
                                      value={formData.email}                          
                                      onChange={this.handleInputChange}                          
                                      required                          
                                    />
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <input
                                      id="phone"                          
                                      type="text"                          
                                      name="phone"                          
                                      placeholder="Phone"                          
                                      value={formData.phone}                          
                                      onChange={this.handleInputChange}                          
                                      />                      
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <select
                                      name="subject"                                                    
                                      value={formData.subject}
                                      onChange={this.handleInputChange}
                                      className={!formData.subject ? 'placeholder' : ''}
                                      style={{ color: formData.subject ? 'black' : '#aaaaaa' }}
                                    >

                                    <option value="" disabled className="placeholder">Enquiry About</option>

                                    <option value="Devops">Devops</option>
                                    <option value="Cloud Migration">Cloud Migration</option>
                                    <option value="IT Cloud Integration">IT Cloud Integration</option>
                                    <option value="Cloud Operation">Cloud Operation</option>
                                    <option value="Mobile/app Development">Mobile/app Development</option>
                                    </select>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <input
                                            id="enquiryDate"
                                            type="Date"
                                            name="enquiryDate"
                                            placeholder="Date"
                                            value={formData.enquiryDate}
                                            onChange={this.handleInputChange}    
                                             style={{height: '48px',width: '100%',color: formData.enquiryDate ? 'black' : '#aaaaaa',padding: '20px', // Add padding for better spacing
                                              boxSizing: 'border-box'}}
                                        />
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <textarea
                                            id="message"
                                            name="message"
                                            placeholder="Message"
                                            value={formData.message}
                                            onChange={this.handleInputChange}
                                            style={{ minHeight: '100px' }}
                                        ></textarea>
                                    </div>

                                    <div className="form-group col-md-12 col-sm-12">
                                        <button type="submit" className="theme-btn btn-style-one">
                                            <span className="btn-title">Make a Request</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

      {/* </section> */}


      <section className="testimonials-section">
        <div
          className="image-layer"
          // style={{
          //   background: `url(${Background2})`,
          //   backgroundAttachment: "fixed",
          //   backgroundRepeat: "no-repeat",
          //   backgroundSize: "100%",
          //   right:'7%'
          // }}
        ></div>                                                 
        <div className="auto-container" >
          <div
            className="carousel-box wow fadeInUp"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
            style={{maxWidth:'1114px'}}
          >
            <OwlCarousel
              className="testimonial-carousel theme-carousel owl-theme owl-carousel"
              loop="true"
              margin={0}
              autoheight="true"
              lazyload="true"
              nav="true"
              dots="true"
              autoplay="true"
              autoplayTimeout={5000}
              smartSpeed={500}
              items={1}
              style={{ marginTop: '-150px' }}
>
              <div className="slide-item">
                <div className="inner">
                  <div className="icon-box">
                    <span className="flaticon-chat-2"></span>
                  </div>
                  <div className="title">
                    Best Services from Kloudping IT Solutions!
                  </div>
                  <div className="text">
                    As technology continues to drive the business world,
                    having a strong IT foundation is essential for the success
                    and survival of organizations. When you work with the best
                    IT services companies in India, you make your business
                    technology competent and get a significant edge over your
                    competitors.
                  </div>
                  <div className="info">
                    <div className="name">
              <a  href="https://www.linkedin.com/in/kloudping-admin?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BwIFUUSFRTSerC06AM619Sw%3D%3D
\" target="_blank">Teena</a></div>
                    <div className="designation">CEO, HR Manager</div>
                  </div>
                </div>
              </div>

              <div className="slide-item">
                <div className="inner">
                  <div className="icon-box">
                    <span className="flaticon-chat-2"></span>
                  </div>
                  <div className="title">
                    Best Services from Kloudping IT Solutions!
                  </div>
                  <div className="text">
                    IT solutions is that they provide the opportunity to use
                    the latest technology in the market.
                  </div>
                  <div className="info">
                    <div className="name"> 
                    <a  href="https://www.linkedin.com/in/kloudping-admin?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BwIFUUSFRTSerC06AM619Sw%3D%3D
\" target="_blank">Teena</a>  
                    </div>
                    <div className="designation">CEO, HR Manager</div>
                  </div>
                </div>
              </div>

              <div className="slide-item">
                <div className="inner">
                  <div className="icon-box">
                    <span className="flaticon-chat-2"></span>
                  </div>
                  <div className="title">
                    Best Services from Kloudping IT Solutions!
                  </div>
                  <div className="text">
                    Services include consulting, software development, systems
                    integration, as well as hardware deployment and education.
                  </div>
                  <div className="info">
                    <div className="name">  <a  href="https://www.linkedin.com/in/kloudping-admin?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BwIFUUSFRTSerC06AM619Sw%3D%3D
\" target="_blank">Teena</a>  </div>
                    <div className="designation">CEO, HR Manager</div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      <section>
      <div className="sec-title centered" >
            <div className="upper-text"style={{marginRight:'3%'}}>CUSTOMERS</div>
            <h2>Esteemed Customers </h2>
          
          </div>
      {/* <div className="client-logo-wrap">
        <ul className="client-logo client-logo-left">
          <li><img src={require("../../images/Client logo/client1.png")}alt="" /></li>
          <li><img src={require("../../images/Client logo/client2.png")}alt="" /></li>
          <li><img src={require("../../images/Client logo/client3.png")}alt="" /></li>
          <li><img src={require("../../images/Client logo/client4.png")}alt="" /></li>
          <li><img src={require("../../images/Client logo/client5.png")}alt="" /></li>
          <li><img src={require("../../images/Client logo/client1.png")}alt="" /></li>
          <li><img src={require("../../images/Client logo/client2.png")}alt="" /></li>
          <li><img src={require("../../images/Client logo/client3.png")}alt="" /></li>
          <li><img src={require("../../images/Client logo/client4.png")}alt="" /></li>
          <li><img src={require("../../images/Client logo/client5.png")}alt="" /></li>
        
          
           <li><img src={require("../../images/Client logo/client2.png")}alt="" /></li>
          <li><img src={require("../../images/Client logo/client3.jpg")} alt="" /></li>
          <li><img src={require("../../images/Client logo/client4.png")} alt="" /></li>
          <li><img src={require("../../images/Client logo/client5.png")} alt="" /></li>
        </ul>
      </div> */}
   <div className="client-logo-wrap">
  <div className="client-logo-slider">
    <ul className="client-logo client-logo-left">
      <li><img src={require("../../images/Client logo/client1.png")} alt="Client 1" /></li>
      <li><img src={require("../../images/Client logo/client2.png")} alt="Client 2" /></li>
      <li><img src={require("../../images/Client logo/client3.png")} alt="Client 3" /></li>
      <li><img src={require("../../images/Client logo/client4.png")} alt="Client 4" /></li>
      <li><img src={require("../../images/Client logo/client5.png")} alt="Client 5" /></li>
      <li><img src={require("../../images/Client logo/client1.png")} alt="Client 1" /></li>
      <li><img src={require("../../images/Client logo/client2.png")} alt="Client 2" /></li>
      <li><img src={require("../../images/Client logo/client3.png")} alt="Client 3" /></li>
      <li><img src={require("../../images/Client logo/client4.png")} alt="Client 4" /></li>
      <li><img src={require("../../images/Client logo/client5.png")} alt="Client 5" /></li>
    </ul>
  </div>
</div>




   
      </section>
  
    </>

    
    
  );
}
}

export default home;
