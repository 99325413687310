import React, { Component } from 'react'
import Back4 from '../../images/background/datainfra.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Link} from 'react-router-dom'

class Datainfrastructure extends Component {
  componentDidMount() {
     }
  render() {
      return (
        <>
           <section className="inner-banner alternate">
        <div className="image-layer" style={{ background: `url(${Back4})` ,backgroundRepeat: "no-repeat",   backgroundPosition: "0% 50%", backgroundSize: "100% 200%"}}></div>
        <div className="auto-container">
            <div className="inner">
                <div className="title-box">
                    <h1>Devops</h1>
                    
                </div>
            </div>
        </div>
          </section>
          <div className="sidebar-page-container services-page">
        <div className="auto-container">
            <div className="row clearfix">
                <div className="content-side col-lg-8 col-md-12 col-sm-12">
                    <div className="services-content">
                        <div className="service-details">
                            <div className="image-box">
                        <img src={require('../../images/DevOps Images/dev1.jpg')} alt="service-single-image"/>
                      </div>
                      <div className="content">
                                <h3 style={{ paddingLeft : '8px' }}>Devops</h3>
                                <div className="text">
                                    <p>In today’s fast-paced digital world, businesses need to deliver software faster
                                         and more reliably than ever before. At Kloudping IT Solution, we empower your development and
                                          operations teams to collaborate seamlessly, ensuring continuous integration, continuous delivery, and consistent 
                                          innovation. With our comprehensive DevOps services, we transform your IT processes into a streamlined, automated, and highly
                                           efficient pipeline.</p>
                        </div>
                        <div className="two-col">
                                    <div className="row clearfix">
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                              <div className="image"><img src={require('../../images/DevOps Images/dev2.jpg')} alt="featured-image-4"/></div>
                                        </div>
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                              <div className="image"><img src={require('../../images/DevOps Images/dev3.jpg')} alt="featured-image-5"/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text">
                                    <h3  style={{ paddingLeft : '7px' }}>Our DevOps Services:</h3>
                                    <b style={{ paddingLeft: '10px' }}>Continuous Integration & Continuous Deployment (CI/CD):
                                    </b>
                                    <p>
                                    Break down silos between development and operations by automating your software delivery pipeline.
                                     We implement CI/CD practices that allow your team to release
                                     new features, updates, and fixes with unprecedented speed and quality.
                                    </p>

                                <b style={{ paddingLeft: '10px' }}>Infrastructure as Code (IaC):</b>

                                    <ul className="list-style-one">
                                         <li>Manage and provision your IT infrastructure through code, ensuring consistency and scalability across your entire organization.
                                             Our experts leverage tools like Terraform, Ansible, and AWS CloudFormation to 
                                            automate infrastructure deployment and management.</li>
                                         </ul>
                               <b style={{ paddingLeft: '10px' }} >Monitoring & Logging:</b>
                           
                                <p>

Gain real-time insights into your systems with advanced monitoring and logging solutions. We help you identify
 and resolve issues proactively, 
ensuring your applications run smoothly and your users remain satisfied.</p>
                           
                            <b style={{ paddingLeft: '10px' }}>Why Choose Us:</b>
                         
                               
                                <ul>
                                    <li style={{ paddingLeft : '8px' }}>
                                    · Proven track record of successful DevOps implementations
                                    </li>
                                <li style={{ paddingLeft : '8px' }}>
                                · Certified experts in leading DevOps tools and practices

                                </li>
                                <li style={{ paddingLeft : '8px' }}>
                                · Customized solutions tailored to your specific needs

                                </li>
                                </ul>


 
 <b style={{ paddingLeft : '8px' }}>Call to Action: </b>
 <p>
Ready to transform your software delivery process? Contact us today for a 
consultation and see how DevOps can accelerate your business growth.</p>
                    

                                    
{/* <h3>DevOps Tech Stack Expertise:</h3>
 
 <b>Our engineers at Kloudping IT Solutions are adept in a diverse tech stack, including:</b>
 <ul className="list-style-one">
 <li> Containerization: Docker, Kubernetes</li>
 <li> Infrastructure as Code: Terraform, Ansible</li> 
 <li> CI/CD: Jenkins, GitLab CI, GitHub Actions</li> 
 <li> Cloud Platforms: AWS, Azure</li> 
 <li> Monitoring & Logging: Prometheus, Grafana, ELK, Splunk, SonarQube</li> 
 <li> Security Automation: Veracode</li> 
 <li> Microservices: Architecture design and implementation</li> 
 </ul> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside className="sidebar services-sidebar">
                        <div className="sidebar-widget services-widget">
                            <div className="widget-inner">
                                <ul>
                                    <li className="active"><a href='/Devops'>Devops</a></li>
                                    <li><a href='/cloudintegration'> Cloud Integration</a></li>
                                    <li><a href='/Migration'> Cloud Migration</a></li>
                                    <li><a href='/Operation'>Cloud Operation</a></li>
                                    <li><a href='/WebAppDevelopment' >Web/App Development</a></li>
                                </ul>
                            </div>
                    </div>
                   
                    <div className="sidebar-widget info-widget">
                            <div className="widget-inner">
                        <div className="image"><Link to="contact"><img src={require('../../images/DevOps Images/dev4.jpg')} alt="featured-image-4"/></Link></div>
                                <div className="lower">
                                    <div className="subtitle">Got any Questions? <br/> Call us Today!</div>
                                    <div className="icon-box"><span className="flaticon-telephone"></span></div>
                                    <div className="phone"><a href="tel:+91 8903500146">+91 8903500146</a></div>
                                    <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
                
            </div>
        </div>
          </div>
          <div className="theme-separator"></div>
                 </>
        )
    }
}
export default Datainfrastructure