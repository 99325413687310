import React, { Component } from 'react'
import Back6 from '../../images/background/banner-bg-1.jpg';
import {Link} from 'react-router-dom'
class Migration extends Component {
  componentDidMount() {
     }
  render() {
      return (
        <>
        <section className="inner-banner alternate">
        <div className="image-layer" style={{ background: `url(${Back6})`}}></div>
        <div className="auto-container">
            <div className="inner">
                <div className="title-box">
                    <h1 >Cloud Migration</h1>
                    <div className="d-text">Seamless Cloud Migration Tailored to Your Business Needs</div>
                </div>
            </div>
        </div>
          </section>
          <div className="sidebar-page-container services-page">
        <div className="auto-container">
            <div className="row clearfix">
                
                
                <div className="content-side col-lg-8 col-md-12 col-sm-12">
                    <div className="services-content">
                        <div className="service-details">
                            <div className="image-box">
                        <img src={require('../../images/DevOps Images/dev1.jpg')} alt="service-single-image"/>
                            </div>
                           
                            <div className="content">
                                <h3 style={{ paddingLeft : '8px' }}>Cloud Migration</h3>
                                <div className="text">
                                    <p>
                                    Migrating to the cloud is a critical step for businesses looking to stay competitive in the digital age. 
                                    At Kloudping IT Solution, we offer a comprehensive range of cloud migration services, ensuring that your transition
                                     is smooth, secure, and optimized for future growth.
                                     Whether you're migrating infrastructure, data, applications, or platforms, our expert team ensures that every aspect of the migration process is 
                                     meticulously planned and executed to meet your business needs
                    
</p>{/* <ul  className="list-style-one"><li>
Customer Relationship Management Systems (CRMS)
    </li>
    <li>Human Resource Management Systems (HRMS)</li>
    <li>Robo Trader Hub</li>
    <li>Cloud Migration</li>
    <li>Cloud Secuirty</li>
    <li>DevOps</li>
    </ul> */}
            

                                </div>
                                
                                <div className="two-col">
                                    <div className="row clearfix">
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                              <div className="image"><img src={require('../../images/DevOps Images/dev2.jpg')} alt="featured-image-4"/></div>
                                        </div>
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                                            <div className="image"><img src={require('../../images/DevOps Images/dev3.jpg')} alt="featured-image-5"/></div>
                                        </div>
                                    </div>
                                </div>
                                <h3 style={{ paddingLeft : '10px' }}>Our Cloud Migration Services:</h3>
                                <div className="text" style={{ paddingLeft : '8px' }}>
                                <b style={{ paddingLeft : '10px' }} >Infrastructure Migration: </b>
                                <p>
                                Transition your IT infrastructure to the cloud with minimal disruption and maximum efficiency. We ensure that your infrastructure is optimized for the cloud environment, providing enhanced scalability and flexibility.
                                </p> 
                                <b style={{ paddingLeft : '10px' }}>Application Migration:</b>
                                <p>
                                Your data is invaluable, and migrating it requires precision and care. We manage data migration with a focus on security and compliance, ensuring that your information is safely transferred without any loss or downtime.
                                </p>
                                <b style={{ paddingLeft : '10px' }}>Application Migration:</b>
                                <p>
                                    Move your applications to the cloud with confidence. We specialize in migrating and optimizing applications for cloud environments, ensuring that they run smoothly and efficiently after the transition.    
                                </p>
                                <b style={{ paddingLeft : '10px' }}>Platform Migration:</b>
                                <p>
                                 
                                    Whether you're switching from one cloud platform to another or moving from an on-premises system to the cloud, we provide seamless platform migration services tailored to your specific requirements.
    
                                </p>
                                <b style={{ paddingLeft : '10px' }}>Optimization:</b>
                                <p>
                                Post-migration, we focus on optimizing your cloud environment to ensure that it meets your business's performance, security, and cost-efficiency goals. We continuously monitor and adjust your cloud setup to maximize its potential.
                                </p>
                                <b style={{ paddingLeft : '10px' }}>
                                Why Choose Us:
                                </b>
                                <p>
                                <ul>
                                    <li> · A comprehensive approach that covers all aspects of cloud migration</li>
                                    <li>·  Expertise in managing complex migrations with minimal risk and disruption
                                    </li>
                                    <li> · Commitment to security, compliance, and ongoing optimization
                                    </li>
                                </ul>
                                </p>
                               <b style={{ paddingLeft : '10px' }}>
                               Call to Action:
                               </b>
                                <p>
                               Looking to migrate to the cloud? Contact us today to schedule your cloud migration consultation and discover how we can 
                               help your business thrive in the cloud.
                                </p>
                                </div>
                               


                              
</div>
                        </div>
                    </div>
                </div>
                <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside className="sidebar services-sidebar">
                        
                        <div className="sidebar-widget services-widget">
                            <div className="widget-inner">
                                <ul>
                                    <li><a href='/Devops'>Devops</a></li>
                                    <li><a href='/cloudintegration'> Cloud Integration</a></li>
                                    <li className="active"><a href='/Migration'>Cloud Migration</a></li>
                                    <li><a href='/Operation'>Cloud Operation</a></li>
                                    <li><a href='/WebAppDevelopment'>Web/App Development</a></li>
                                </ul>
                            </div>
                    </div>
                   
                    <div className="sidebar-widget info-widget">
                            <div className="widget-inner">
                        <div className="image"><Link to="contact"><img src={require('../../images/DevOps Images/dev4.jpg')} alt="featured-image-4"/></Link></div>
                                <div className="lower">
                                    <div className="subtitle">Got any Questions? <br/> Call us Today!</div>
                                    <div className="icon-box"><span className="flaticon-telephone"></span></div>
                                    <div className="phone"><a href="tel:+91 8903500146">+91 8903500146</a></div>
                                    <div className="email"><a href="mailto:info@kloudping.com">info@kloudping.com</a></div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
                
            </div>
        </div>
          </div>
          <div className="theme-separator"></div>
        </>
        )
    }
}
export default Migration