import React, { Component } from 'react'
import "../footer/footer.css";
import {Link} from 'react-router-dom'
class footer extends Component {
    componentDidMount() {


    }
    render() {
        return (
            <>
                <footer className="main-footer">
                    <div className="top-pattern-layer-dark"></div>


                    <div className="widgets-section">
                        <div className="auto-container">
                            <div className="row clearfix">


                                <div className="column col-xl-5 col-lg-12 col-md-12 col-sm-12">
                                    <div className="footer-widget about-widget">
                                        
                                        <div className="logo">
                                            <a href="/"><img src={require('../../../images/logo.png')} alt="footer-logo" className='footer logo' /></a>
                                        </div>
                                        <div className="text">
                                        <b style={{fontSize: 'x-large'}}>Ready to make an impact? </b><br/><br/>
 
 Your ideas have the potential to reshape the future. Let's collaborate to turn your boldest ideas into exceptional, transformative experiences. Reach out to us today, and let’s create something extraordinary together.
                                        </div>
                                        <div className="info">
                                            <ul>
                                                <li>No:2,Easwaran Kovil Street (RamaComplex 2nd Floor),Tindivanam,Tamil Nadu 604001</li>
                                                <li><span className="icon sl-icon-call-in"></span> <a href="tel:+91 89035 00146"><strong>+91 89035 00146</strong></a></li>

                                                <li>
                                                    <span className="icon sl-icon-envelope-open"></span><a href="mailto:&#105;&#110;&#102;&#111;&#064;&#107;&#108;&#111;&#117;&#100;&#112;&#105;&#110;&#103;&#046;&#099;&#111;&#109;">
                                                        <strong> info@kloudping.com  </strong>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="column col-xl-7 col-lg-10 col-md-12 col-sm-12">
                                    <div className="footer-widget links-widget">
                                        <div className="widget-content">
                                            <div className="row clearfix">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="row clearfix">
                                                        <div className="column col-lg-4 col-md-2 col-sm-12">
                                                            <div className="widget-title">
                                                                <h4>SERVICES </h4>
                                                            </div>
                                                            <ul className="links">
                                                                <li><Link to='/devops'>DevOps</Link></li>
                                                                <li><Link to='/Cloudintegration'>Cloud Integration</Link></li>
                                                                <li><Link to='/Migration'>Cloud Migration</Link></li>
                                                                <li><Link to='/Operation'>Cloud Operation</Link></li>
                                                                <li><Link to='/WebAppDevelopment'>Web/App Application Development </Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="column col-lg-4 col-md-4 col-sm-12">
                                                            <div className="widget-title">
                                                                <h4>What We Do ?</h4>
                                                            </div>
                                                            <ul className="links">
                                                                <li><Link to='about'>Understand Client's Needs</Link></li>
                                                                <li><Link to='/Clientpartner'>Partner With Clients</Link></li>
                                                                <li><Link to='faq'>Trust Worthy Approach</Link></li>
                                                                <li><Link to='faq'>Highly Skilled Team</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="column col-lg-4 col-md-4 col-sm-12">
                                                            <div className="widget-title">
                                                                <h4>Career and jobs</h4>
                                                            </div>
                                                            <ul className="links">
                                                                {/* <li><Link to='blogfullPost'>Kloudping Academy</Link></li> */}
                                                                <li><a href='https://www.linkedin.com/company/kloudpingacademy/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BMk%2FHhAI0TXqhsPFLZRJIow%3D%3D' target='_blank' rel='noopener noreferrer'>Kloudping Academy</a></li>
                                                                {/* <li><Link to='News'>Training & Internships</Link></li> */}
                                                                <li><a href="https://www.linkedin.com/company/kloudping/posts/?feedView=all" target="_blank" rel="noopener noreferrer">Training & Internships  </a></li>
                                                                {/* <li><Link to='blogfullPost'>Technology Consulting</Link></li>
                                                                <li><Link to='contact'>Terms Of Service</Link></li>
                                                                <li><Link to='contact'>Contact Us</Link></li> */}
                                                            </ul> 
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="footer-bottom">
                        <div className="auto-container">
                            <div className="inner">
                            &nbsp;   &nbsp;    <div className="copyright">&copy; 2024 <strong>Kloudping IT Solutions</strong>. All rights reserved. </div>
                            </div>
                        </div>
                    </div>

                </footer>

            </>
        )
    }
}


export default footer