import React from "react";
import './Client.css'
function Client(){

  return(
    <>
   <section>
      <div className="Client" >
           
            <h2>OUR PARTNER </h2>
          
          </div>
      <div className="partner">
        <ul className="partner-box">
          <li><img src={require("../../images/Kloudping partners/c4.png")}alt="" /></li>
          <li><img src={require("../../images/Kloudping partners/c1.png")}alt="" /></li>
          <li><img src={require("../../images/Kloudping partners/c2.png")}alt="" /></li>
          {/* <li><img src={require("../../images/Kloudping partners/c3.png")}alt="" /></li> */}
          <li><img src={require("../../images/Kloudping partners/c5.png")}alt="" /></li>
          {/* <li><img src={require("../../images/")}alt="" /></li>
          <li><img src={require("../../images/")}alt="" /></li>
          <li><img src={require("../../images/")}alt="" /></li> */}
         
      
      
      
        </ul>
      </div>
   
      </section>
    </>
  )

}

export default Client;