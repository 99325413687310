import React, { Component } from 'react'
import Back7 from '../../images/background/productengg.jpg'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class Operation extends Component {
  componentDidMount() {
     }
  render() {
      return (
        <>
          <section className="inner-banner alternate">
        <div className="image-layer" style={{ background: `url(${Back7})`,backgroundRepeat: "no-repeat",   backgroundPosition: "0% 55%", backgroundSize: "110% 220%"}}></div>
        <div className="auto-container">
            <div className="inner">
                <div className="title-box">
                    <h1>Cloud operation</h1>
                    <div className="d-text">Optimize Your Cloud Operations for Maximum Efficiency</div>
                </div>
            </div>
        </div>
          </section>
          <div className="sidebar-page-container services-page">
          <div className="auto-container">
              <div className="row clearfix"> 
              <div className="content-side col-lg-8 col-md-12 col-sm-12">
                    <div className="services-content">
                        <div className="service-details">
                            <div className="image-box">
                                <img src={require('../../images/DevOps Images/dev1.jpg')} alt="service-single-image"/>
                    </div>
                    <div className="content">
                                <h3 style={{ paddingLeft : '8px' }}>Cloud operations:</h3>
                                <div className="text">
                                    <p>Operating in the cloud offers numerous advantages, but maintaining peak performance, security, and efficiency 
                                        requires ongoing management and automation. At Kloudping IT Solution, our cloud operation services are designed to ensure that 
                                        your cloud environment is always running at its best. By leveraging automation and orchestration techniques, we streamline cloud deployments, enhance operational efficiency, and ensure that your cloud infrastructure 
                                        is optimized for performance and cost-effectiveness</p>
                                    {/* <p>We offer full-cycle development services, from ideation to maintenance, and have expertise in various technologies. We create prototypes and MVPs for rapid idea validation and maintain a strong focus on scalability, performance, UX/UI design, security, and quality assurance.</p>
                                    <p>We ensure seamless integration and compatibility with existing systems and offer ongoing support. By optimizing costs and project management, We can help businesses turn concepts into reliable, efficient, and competitive software products.</p> */}
                      </div>
                      <div className="two-col">
                                    <div className="row clearfix">
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                            <div className="image"><img src={require('../../images/DevOps Images/dev2.jpg')} alt="featured-image-4"/></div>
                                        </div>
                                        <div className="column col-lg-6 col-md-6 col-sm-12">
                            <div className="image"><img src={require('../../images/DevOps Images/dev3.jpg')} alt="featured-image-5"/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text">
                                    <h3 style={{ paddingLeft : '7px' }}>Our Cloud Operation Services:</h3>
                                    <b style={{ paddingLeft : '10px' }}>
                                    Automation and Orchestration: 
                                    </b>
                                    <p>
                                   We use cutting-edge automation and orchestration techniques to manage and streamline your cloud operations. From deployment 
                                   automation to orchestration of complex workflows, we ensure that your cloud environment is efficient, reliable, and easy to manage.
                                    </p>
                                   <b style={{ paddingLeft : '10px' }}>
                                   Deployment Automation:
                                   </b>
                                   <p>
                                    Automating cloud deployments is key to reducing manual errors and speeding up the delivery of new features and updates. Our deployment automation services ensure that your applications are deployed consistently and efficiently, with minimal downtime.
                                  </p>
                                   <b style={{ paddingLeft : '10px' }}>
                                    Monitoring Automation:
                                   </b>
                                  <p>
                                  Continuous monitoring is essential for maintaining the health of your cloud environment. We automate monitoring processes to provide real-time insights into your system’s performance, allowing us to identify and address issues proactively.

                                  </p>
                                  <b style={{ paddingLeft : '10px' }}>
                                   Pipeline Management:
                                  </b>
                                  <p>
                                  We design and implement automated pipelines that streamline cloud deployments, from development to production. This approach ensures that your applications are continuously integrated and delivered, reducing time-to-market and improving overall efficiency
                                </p>
                                <b style={{ paddingLeft : '12px' }}>
                                Why Choose Us:
                                </b>
                                <p>
                                <ul>
                                <li>· Expertise in automating and orchestrating cloud operations</li>
                                <li>· Proactive management that keeps your cloud environment secure and efficient</li>
                                <li>· Customized solutions tailored to your unique operational needs</li>
                                </ul>
                                </p>
                                 <b style={{ paddingLeft : '10px' }}>Call to Action: </b>
                                 <p>
                                  Ensure your cloud operations are optimized for success. Contact us today to learn more about our cloud operation services and how we can help you 
                                  achieve maximum efficiency.

                                 </p>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>
              <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                    <aside className="sidebar services-sidebar">
                        <div className="sidebar-widget services-widget">
                            <div className="widget-inner">
                                <ul>
                                    <li><a href='/Devops'>Devops</a></li>
                                    <li><a href='/cloudintegration'> Cloud Integration</a></li>
                                    <li><a href='/Migration'> Cloud Migration</a></li>
                                    <li className="active"><a href='/Operation'> Cloud operation</a></li>
                                    <li><a href='/WebAppDevelopment'>Web/App Development</a></li>
                                </ul>
                            </div>
                  </div>
                 
                  <div className="sidebar-widget info-widget">
                            <div className="widget-inner">
                      <div className="image"><a href="/contact"><img src={require('../../images/DevOps Images/dev4.jpg')} alt="featured-image-4"/></a></div>
                                <div className="lower">
                                    <div className="subtitle">Got any Questions? <br/>Call us Today!</div>
                                    <div className="icon-box"><span className="flaticon-telephone"></span></div>
                                    <div className="phone"><a href="tel:+91 89035 00146">+91 89035 00146</a></div>
                                    <div className="email"><a href="mailto:info@kloudping.com.net">info@kloudping.com.net</a></div>
                              </div>
                        
                            </div>
                        </div>
                    </aside>
                  </div>
                </div>
            </div>
            </div>
          <div className="theme-separator"></div>
         </>
                )
            }
        }
export default Operation