import React, { Component } from 'react';
import Pop from './Pop';
import kloudpingIcon from '../../images/kloudpingicon.png'; 

class Windowpop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popvisible: true,
    };
  }

  togglePop = () => {
    this.setState((prevState) => ({
      popvisible: !prevState.popvisible,
    }));
  };

  handleClose = () => {
    this.setState({ popvisible: false });
  };

  render() {
    const { popvisible } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        {/* {!popvisible && (
          <img
            src={kloudpingIcon}
            alt="Kloudping"
            style={{
              position: 'fixed',
              left: '20px',
              bottom: '41px',
              width: '70px',
              cursor: 'pointer',
              zIndex: 2,
            }}
            onClick={this.togglePop}
          />
        )} */}
        {popvisible && <Pop open={popvisible} onClose={this.handleClose} />}
      </div>
    );
  }
}

export default Windowpop;
