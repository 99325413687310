import React, { Component } from 'react';
import Background3 from '../../images/background/contactus.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contact.css';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                name: '',
                phone: '',
                enquiryDate: '',
                email: '',
                subject: '',
                message: ''
            }
        };
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        this.setState({
            formData: {
                name: params.get('name') || '',
                phone: params.get('phone') || '',
                email: params.get('email') || '',
                subject: params.get('subject') || '',
                message: params.get('message') || '',
                enquiryDate: params.get('enquiryDate') || ''
            }
        });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { formData } = this.state;

        try {
            const response = await fetch("https://api-mysales.kloudping.com/api/Query/QueryEmail", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                toast.success("Message Sent Successfully!");
                this.setState({
                    formData: {
                        name: '',
                        phone: '',
                        enquiryDate: '',
                        email: '',
                        subject: '',
                        message: ''
                    }
                });
            } else {
                toast.error("Error sending message.");
            }
        } catch (error) {
            toast.error("Error sending message.");
        }
    };

    render() {
        const { formData } = this.state;

        return (
            <>
                <section className="inner-banner alternate">
                    <div className="image-layer" style={{ background: `url(${Background3})`, backgroundRepeat: "no-repeat", backgroundPosition: "0% 50%", backgroundSize: "100% 200%" }}></div>
                    <div className="auto-container">
                        <div className="inner">
                            <div className="title-box"></div>
                        </div>
                    </div>
                </section>

                <section className="contact-section-two">
                    <div className="auto-container">
                        <div className="upper-row">
                            <div className="row clearfix">
                                <div className="text-column col-lg-6 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="sec-title">
                                            <div className="upper-text">Kloudping - Send us a Message</div>
                                            <h3>Do You Have Any Questions?</h3>
                                            <h3>We’ll Be Happy To Assist!</h3>
                                            <div className="lower-text" style={{ margin: '-10px auto' }}>
                                                We will respond to your inquiry as soon as possible. Right now, we receive many inquiries and it may take a little longer than normal before you get a reply. If you need urgent help, we recommend that you call us during our opening hours..
                                            </div>
                                        </div>
                                        <div className="social-links">
                                            <ul className="clearfix">
                                                <li><a href="https://www.linkedin.com/company/kloudping" target='_blank' rel="noopener noreferrer"><span className="fab fa-linkedin"></span></a></li>
                                                <li><a href="https://www.instagram.com/kloudping_it_solutions/" target='_blank' rel="noopener noreferrer"><span className="fab fa-instagram"></span></a></li>
                                                <li><a href="https://www.youtube.com/@Kloudping" target='_blank' rel="noopener noreferrer"><span className="fab fa-youtube"></span></a></li>
                                                <li><a href="https://wa.me/+918903500146" target='_blank' rel="noopener noreferrer"><span className="fab fa-whatsapp"></span></a></li>
                                                <li><a href="https://www.facebook.com/Kloudpingitsolutions/" target='_blank' rel="noopener noreferrer"><span className="fab fa-facebook-square"></span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-column col-lg-6 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="form clearfix">
                                            <div className='form-box'>
                                                <form className="default-form contact-form" onSubmit={this.handleSubmit}>
                                                    <div className="row clearfix">
                                                        <h2>Contact Us</h2>
                                                        <p>Fill out the form and we'll be in touch soon!</p>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group txtOnly">
                                                            <input
                                                                id="name"
                                                                type="text"
                                                                name="name"
                                                                placeholder="Your Name"
                                                                value={formData.name}
                                                                onChange={this.handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                            <input
                                                                id="email"
                                                                type="email"
                                                                name="email"
                                                                placeholder="Email"
                                                                value={formData.email}
                                                                onChange={this.handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group numberOnly">
                                                            <input
                                                                id="phone"
                                                                type="text"
                                                                name="phone"
                                                                placeholder="Phone"
                                                                value={formData.phone}
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group contactform">
                                                            <select
                                                                className="custom-select-box"
                                                                name="subject"
                                                                value={formData.subject}
                                                                onChange={this.handleInputChange}
                                                                style={{ color: formData.subject ? 'black' : '#aaaaaa' }}
                                                            >
                                                                <option value="" disabled>Enquiry About</option>
                                                                <option value="Devops">Devops</option>
                                                                <option value="Cloud Migration">Cloud Migration</option>
                                                                <option value="IT Cloud Integration">IT Cloud Integration</option>
                                                                <option value="Cloud Operation">Cloud Operation</option>
                                                                <option value="Mobile/app Development">Mobile/app Development</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                            <input
                                                                id="enquiryDate"
                                                                type="date"
                                                                name="enquiryDate"
                                                                placeholder="Date"
                                                                value={formData.enquiryDate}
                                                                onChange={this.handleInputChange}
                                                                style={{ color: formData.enquiryDate ? 'black' : '#aaaaaa' }}
                                                            />
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                            <textarea
                                                                id="message"
                                                                name="message"
                                                                placeholder="Message"
                                                                value={formData.message}
                                                                onChange={this.handleInputChange}
                                                            ></textarea>
                                                        </div>
                                                        <div className="form-group col-md-12 col-sm-12">
                                                            <button type="submit" className="theme-btn btn-style-one">
                                                                <span className="btn-title">Make a Request</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lower-row">
                            <div className="row clearfix">
                                <div className="contact-info-block col-lg-12 col-md-12 col-sm-12">
                                    <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="content-box">
                                            <div className="title-box" style={{ textAlign: 'center' }}>
                                                <h4><Link to='contact'>Kloudping IT Solutions</Link></h4>
                                                <div className="sub-text">Tindivanam</div>
                                            </div>
                                            <div className="text-content">
                                                <div className="info">
                                                    <ul>
                                                        <li>No:2,Easwaran Kovil Street (RamaComplex 2nd Floor),Tindivanam,Tamil Nadu 604001</li>
                                                        <li>Call us <a href="tel:+918903500146"><strong>+91 8903500146</strong></a></li>
                                                        <li><a href="mailto:info@kloudping.com"><strong>info@kloudping.com</strong></a></li>
                                                    </ul>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact-info-block col-lg-12 col-md-12 col-sm-100">
                                <script src="https://maps.googleapis.com/maps/api/js?key="></script>
                                <iframe class="map-canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6557.5275173607015!2d79.65161392390385!3d12.239530788705046!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a536dfb538e56f5%3A0x4c7f88783e100bfe!2sKloudping%20IT%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1727699724135!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ToastContainer />
            </>
        );
    }
}

export default Contact;
